<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/locale/pt-br';
import ProductService from '@/services/product'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Regras de Limite de Produtos (Mobwit)",
  },
  components: { Layout, PageHeader, Multiselect },
  directives: { money: VMoney },
  data() {
    return {
      title: "Regras de Limite de Produtos (Mobwit)",
      loading: false,
      productLimitRules: [],
      productLimitRule: {},
      products: [],
      modalError: '',
      productLimitRuleToRemove: '',
      isLoading: false,

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'productDescription', sortable: false, label: 'Produto' },
        { key: 'quantity', sortable: false, label: 'Qtde. Máxima' },
        { key: 'registerDate', sortable: false, label: 'Data de Cadastro', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY hh:mm:ss');
        } },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true, page = 1, term = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const productLimitRulesResult = await ProductService.getProductLimitRuleMobwit(term, page);
        this.productLimitRules = productLimitRulesResult.data.productLimitRules;

        this.count = productLimitRulesResult.data.count;
        this.currentCount = productLimitRulesResult.data.currentCount;
        this.currentPage = productLimitRulesResult.data.currentPage;
        this.totalPages = productLimitRulesResult.data.totalPages;
        this.perPage = productLimitRulesResult.data.perPage;
        this.startIndex = productLimitRulesResult.data.startIndex;
        this.endIndex = productLimitRulesResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async saveProductLimitRule() {
      this.modalError = '';

      if (!this.productLimitRule._id && !this.productLimitRule.product) {
        return this.modalError = 'Informe o produto da regra';
      }

      const data = {
        ...this.productLimitRule,
        quantity: Number(this.productLimitRule.quantity.toString().replace('.', '').replace(',', '.')),
        origin: 'mobwit',
      }

      if (!this.productLimitRule._id) {
        data.productCode = this.productLimitRule.product.code;
      }

      if (!data.quantity) {
        return this.modalError = 'Informe a quantidade máxima corretamente';
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.productLimitRule._id) {
          await ProductService.updateProductLimitRule(data);
        } else {
          await ProductService.createProductLimitRule(data);
        }

        loader.hide();

        this.load(false);

        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newProductLimitRule() {
      this.productLimitRule = {
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editProductLimitRule(item) {
      this.productLimitRule = {
        _id: item._id,
        productDescription: item.productDescription,
        quantity: item.quantity.toString().replace('.', ','),
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeProductLimitRule() {
      this.$bvModal.hide('modal-remove');

      ProductService.removeProductLimitRule(this.productLimitRuleToRemove).then(() => {
        this.load(false);
        this.$toast.open('Regra removida com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover a regra');
      })
    },
    findProduct(term) {
      this.isLoading = true;

      ProductService.getUniqueProducts(term).then(res => {
        this.isLoading = false;

        this.products = res.data;
      }).catch(() => {
        this.isLoading = false;
      })
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por nome do grupo"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newProductLimitRule()">
                    <i class="mdi mdi-plus mr-1"></i> Nova Regra  
                  </button>
                </div>
              </div>
            </div>  

            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="productLimitRules"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editProductLimitRule(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="productLimitRuleToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="productLimitRule._id ? 'Alterando Regra' : 'Nova Regra'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-9">
          <div class="form-group">
            <label class="control-label">Produto</label>
            
            <input
              id="productDescription"
              name="productDescription"
              type="text"
              class="form-control"
              :disabled="true"
              v-if="productLimitRule._id"
              :value="productLimitRule.productDescription"
            />

            <!-- <multiselect
              v-model="productLimitRule.department"
              :options="departments"
              placeholder="Selecione o departamento"
              label="description"
              track-by="_id"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!productLimitRule._id"
            ></multiselect> -->

            <multiselect
              v-model="productLimitRule.product"
              :options="products"
              :close-on-select="true"
              :clear-on-select="true"
              :loading="isLoading"
              @search-change="findProduct"
              placeholder="Selecione o produto por nome ou código"
              label="fullDescription"
              track-by="code"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!productLimitRule._id"
            ></multiselect>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="productStock">Qtde. Máxima</label>

            <input
              id="quantity"
              name="quantity"
              type="tel"
              class="form-control"
              v-model="productLimitRule.quantity"
            />
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveProductLimitRule()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Regra" title-class="font-18" centered>
      <p>Confirma a exclusão da regra?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeProductLimitRule">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }
</style>